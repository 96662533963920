const { isset, asArray, isEmptyOrNotSet } = require('@hints/utils/data');
const { isObject } = require('@hints/utils/types');
const RRule = require('rrule');
const { isGenerationDateAfterStart, isGenerationDateBeforeEnd, isGenerationDateBetween } = require('./recurring-manager-utils');
const { getDateAsInt8, getInt8AsDate } = require('@hints/utils/date');
const { isSameDay } = require('date-fns');
    
/** @type {Record<number, RRule.Frequency>} */
const Frequency_RRule_Map = {
    yearly: RRule.Frequency.YEARLY,
    monthly: RRule.Frequency.MONTHLY,
    weekly: RRule.Frequency.WEEKLY,
    daily: RRule.Frequency.DAILY,
};

/** @type {Record<number, RRule.Weekday>} */
const Day_RRule_Map = {
    0: RRule.RRule.MO,
    1: RRule.RRule.TU,
    2: RRule.RRule.WE,
    3: RRule.RRule.TH,
    4: RRule.RRule.FR,
    5: RRule.RRule.SA,
    6: RRule.RRule.SU,
};

function validateRecurringRules(recurringObj) {
    if (isEmptyOrNotSet(recurringObj.rules)) return false;
    if (isEmptyOrNotSet(recurringObj.startDate)) return false;
    const rules = recurringObj.rules || {};
    if (!isset(Frequency_RRule_Map[rules.frequency])) return false;
    if (!isEmptyOrNotSet(rules.months) && rules.months.some(m => m < 1 || m > 12)) return false;
    if (!isEmptyOrNotSet(rules.days) && rules.days.some(d => d < 1 || d > 31)) return false;
    if (!isEmptyOrNotSet(rules.weekdays) && rules.weekdays.some(m => (
        !isObject(m) ||
        m.day < 0 || m.day > 6 ||
        m.offset < -5 || m.offset > 5
    ))) return false;
    return true;
}

function sanitizeRecurringForRules(recurringObj) {
    delete recurringObj.frequencies;
    delete recurringObj.dates;

    const rules = recurringObj.rules || {};
    recurringObj.rules = {
        frequency: rules.frequency || 'yearly',
        interval: isset(rules.interval) ? rules.interval : 1,
        count: isset(rules.count) ? rules.count : null,
        months: (asArray(rules.months) || []).filter(isset),
        days: (asArray(rules.days) || []).filter(isset),
        weekdays: (asArray(rules.weekdays) || []).filter(isset),
    };
}

function _getFreq(frequency) {
    return Frequency_RRule_Map[frequency];
}

function _getWeekDay(weekday) {
    const rruleWeekday = Day_RRule_Map[weekday.day];
    if (!isset(rruleWeekday)) return undefined;
    if (isset(weekday.offset) && weekday.offset !== 0) return rruleWeekday.nth(weekday.offset);
    return rruleWeekday;
} 

function getRRUle(rule, startDate, endDate) {
    const freq = _getFreq(rule.frequency);
    const wkst = RRule.RRule.MO;
    const dtstart = isset(startDate) ? startDate : undefined;
    const until = isset(endDate) ? endDate : undefined;
    const interval = isset(rule.interval) && rule.interval > 0 ? rule.interval : 1;
    const count = isset(rule.count) && rule.count > 0 ? rule.count : undefined;
    const bymonth = !isEmptyOrNotSet(rule.months) ? (asArray(rule.months) || []).map(parseInt).filter(isset) : undefined;
    const bymonthday = !isEmptyOrNotSet(rule.days) ? (asArray(rule.days) || []).map(parseInt).filter(isset) : undefined;
    const byweekday = !isEmptyOrNotSet(rule.weekdays) ? (asArray(rule.weekdays) || []).map(_getWeekDay).filter(isset) : undefined;

    return new RRule.RRule({
        dtstart: dtstart,
        until: until ? until : undefined,
        interval,
        count,
        bymonthday,
        bymonth,
        byweekday,
        freq,
        wkst,
    });
}

function getRuleNextRunDatesBetween(rule, datesToGenerate = 1, startDate, endDate = null, minDate = null, maxDate = null) {
    if (isEmptyOrNotSet(rule)) return [];
    const rrule = getRRUle(rule, startDate, endDate);
    const nextRunDates = [];

    let lastDate = minDate || startDate;

    // Check if the first date (including startDate) should be included in the next run dates
    // If the first date generated is equal to the startDate of the recurring, and that this date is a valid date (checked against min/max dates), then the date is a valid recurrence.
    const firstRecurring = rrule.after(lastDate, true);
    if(isSameDay(lastDate, firstRecurring) && isGenerationDateBetween(firstRecurring, startDate, endDate, minDate, maxDate)) nextRunDates.push(firstRecurring);
    while (nextRunDates.length < datesToGenerate) {
        const date = rrule.after(lastDate);
        if (!isset(date)) break;
        lastDate = date;
        if (!isGenerationDateAfterStart(date, startDate, minDate)) continue;
        if (!isGenerationDateBeforeEnd(date, endDate, maxDate)) break;
        nextRunDates.push(getInt8AsDate(getDateAsInt8(date)));
    }
    return nextRunDates;
}

module.exports = {
    getRRUle,
    validateRecurringRules,
    sanitizeRecurringForRules,
    getRuleNextRunDatesBetween,
};